.aboutus{
    margin:30px;
    text-align: center;
    &-title{
        font-size: 45px;
        margin-top: 50px;
    }
    &-description{
        margin: 10px auto;
        max-width: 900px;
        font-size: 20px;
        margin-top:50px;
        margin-bottom:50px;
        font-weight: 200;
    }
    &-btn-container{
        width: fit-content;
        margin: 10px auto;
        margin-bottom: 40px;
        text-align: center;
    }
}