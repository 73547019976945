$white: #ffff;
$blue: #2b3a42;
$black: #242424;
$btn_white: #fff;
$btn_black: #242424;
$nav_background: #2b3a42;
$footer_background: #2b3a42;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

$fonts: 'Outfit'; 
