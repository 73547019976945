.btn-hover {
  transition: all 0.3s ease-out;
  background: white;
  color: black;
  transition: 250ms;
}

.btn {
  padding: 8px 20px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;

  p {
    font-family: Outfit;
    font-size: 18px;
    font-weight: 500;

  }

  &--contactus {
    &--black {
      background-color: black;
      color: white;
      border: 2px solid $btn_white;
      display: flex;
      height: 55px;
      align-items: center;

      &:hover {
        background-color: #407BFF;
        color :white;
        transition: background 0.5s;
      }

    }
   

    &--blue {
      background-color: #407BFF;
      color: white;
      padding: 8px 20px;
      border: 2px solid #407BFF;
      transition: all 0.3s ease-out;
      display: flex;
      height: 55px;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #407BFF;
        color :white;
        transition: background 0.5s;
      }
    }

    &--image {
      height: 29px;
      width: 29px;
      margin-right: 8px;
    }

  }

  &--viewportfolio {
    &--black {
      background-color: black;
      color: white;
      border: 2px solid $btn_white;
      display: flex;
      height: 55px;
      align-items: center;
      &:hover {
        background-color: #407BFF;
        color :white;
        transition: background 0.5s;
      }
    }

    &--outline {
      background-color: transparent;
      color: $btn_black;
      padding: 8px 20px;
      border: 2px solid $btn_black;
      transition: all 0.3s ease-out;
      display: flex;
      height: 55px;
      align-items: center;

      &:hover{
        background-color: #407BFF;
        color :white;
        border: 2px solid white;
        transition: background 0.5s;
      }
    }

    &--image {
      height: 29px;
      width: 29px;
      margin-right: 8px;
    }

  }

  &--callbutton {
    &--black {
      background-color: black;
      color: white;
      border: 2px solid $btn_white;
      display: flex;
      height: 55px;
      align-items: center;

      &:hover {
        background-color: #407BFF;
        color :white;
        transition: background 0.5s;
      }
    }

    &--outline {
      background-color: transparent;
      color: $btn_black;
      padding: 8px 20px;
      border: 2px solid $btn_black;
      transition: all 0.3s ease-out;
      display: flex;
      height: 55px;
      align-items: center;

    }

    &--image {
      height: 29px;
      width: 29px;
      margin-right: 8px;
    }

  }

  &--outline {
    background-color: transparent;
    color: $btn_black;
    padding: 8px 20px;
    border: 2px solid $btn_black;
    transition: all 0.3s ease-out;
    display: flex;
    height: 55px;
    align-items: center;

    &--image {
      height: 23px;
      width: 23px;
      margin-right: 8px;
    }
    &:hover {
        background-color: #407BFF;
        color :white;
        transition: background 0.5s;
      }
  }

  &--medium {
    padding: 8px 20px;
    font-size: 18px;

    &:hover {
      @extend .btn-hover;
    }
  }

  &--large {
    padding: 12px 26px;
    font-size: 20px;

    &:hover {
      @extend .btn-hover;
    }
  }
}

.btn-mobile {
  text-decoration: none;
}