.whySection {
  &--container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #DBD9D9;
  }

  &--titleContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
      text-align: center;
      font-weight: 500;
      line-height: 87px;
      font-size: 20px;
      color: #555555;
      display: block;
    }

    h1 {
      font-weight: 700;
      line-height: 76px;
      font-size: 63px;
      text-wrap: balance;
      text-align: center;
      max-width: 1017px;
      display: block;
    }
  }

  &--cardContainer {
    display: flex;
    width: 100%;
    padding-left: 96px;
    padding-right: 96px;
    padding-top: 50px;
    gap: 30px;
    padding-bottom: 50px;
    border-bottom: 1px solid #DBD9D9;

    &--left {
      flex: 50%;
    }

    &--right {
      flex: 50%;
      display: flex;
      flex-direction: column;

      div {
        flex: 50%;
      }

      gap: 30px;
    }
  }
}

.card1 {
  background-color: #f6c8d3;
  padding: 30px;
  border-radius: 20px;
  height: 100%;
  text-align:center;

  h3 {
    font-size: 27px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: start;
    margin-bottom: 10px;
  }

  p {
    font-size: 23px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  img {
    width: 30.75rem;
    height: 30.75rem;
  }
}

.card2 {
  background-color: #f7d0a7;
  padding: 40px;
  border-radius: 20px;
  height: 100%;

  &--container {
    display: flex;
    flex-direction: row;
  }

  h3 {
    font-size: 27px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: start;
    margin-bottom: 10px;
  }

  p {
    font-size: 23px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    align-items: end;
  }

  img {
    width: 249px;
    height: 249px;
    align-content: end;
  }
}

.card3 {
  background-color: #b7dbff;
  padding: 40px;
  border-radius: 20px;
  height: 100%;

  h3 {
    font-size: 27px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: start;
    margin-bottom: 10px;
  }

  p {
    font-size: 23px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  img {
    width: 217px;
    height: 217px;
  }
}

.get-call {
  &-container {
    display: flex;
    margin-left: 96px;
    margin-right: 96px;
    padding-top: 50px;
    padding-bottom: 50px;


    &-text {
      flex: 50%;
      display: flex;
      justify-content: center;
      padding-left: 25px;

      p {
        font-size: 23px;
        max-width: 500px;
        font-weight: 300;
      }
    }

    &-btn {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .whySection {
    &--titleContainer {
  
      h2 {
        font-size: 16px;
      }
  
      h1 {
        font-weight: 700;
        line-height: 50px;
        font-size: 50px;
        text-wrap: balance;
        text-align: center;
        max-width: 325px;
      }
    }

    &--cardContainer {
      padding-left: 21px;
      padding-right: 21px;
      padding-top: 50px;
      flex-direction: column;

  
      &--left {
        flex: 50%;
      }
  
      &--right {
        flex: 50%;
        display: flex;
        flex-direction: column;
  
        div {
          flex: 50%;
        }
  
        gap: 30px;
      }
    }
  }
  .card1 {
    background-color: #f6c8d3;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    h3 {
      font-size: 22px;
      text-align: center;
    }
  
    p {
      font-size: 18px;
    }
  
    img {
      width: 200px;
      height: 305px;
    }
  }

  .card2 {
    background-color: #f7d0a7;
    padding: 40px;
    border-radius: 20px;
    height: 100%;
  
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    h3 {
      font-size: 22px;
      text-align: center;
    }
  
    p {
      font-size: 18px;
      text-align: center;
    }
  
    img {
      width: 249px;
      height: 249px;
      align-content: end;
    }
  }
  .card3 {
  
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    h3 {
      font-size: 22px;
      text-align: center;
    }
  
    p {
      font-size: 18px;
      text-align: center;
    }
  
    img {
      width: 249px;
      height: 249px;
      align-content: end;
    }
  }
  .get-call {
    &-container {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 50px;
      padding-bottom: 50px;
  
  
      &-text {
        flex: 50%;
        display: flex;
        justify-content: center;
        padding-left: 0;
        padding-bottom: 30px;
  
        p {
          font-size: 23px;
          max-width: 500px;
          font-weight: 300;
          text-align: center;
        }
      }
  
      &-btn {
        flex: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}