.footer {
    background-color: black;
    padding: 60px;
    color: white;

    &-container {
        display: flex;
        flex-direction: row;
        margin-top: 25px;


        &-left {
            flex: 50%;

            h6 {
                font-size: 23px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                color: #407BFF;
                margin-bottom: 10px;
            }

            h1 {
                font-size: 85px;
                font-weight: 700;
                letter-spacing: 0em;
                text-align: left;
                color: #FFFFFF;
                width: 608px;

            }

            &-desc {
                font-size: 25px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                margin-top: 15px;
                margin-bottom: 25px;
            }
        }

        &-right {
            flex: 50%;
            display: flex;
            justify-content: center;

            img {
                width: 542px;
                height: 542px;

            }
        }
    }

    &-bottom {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        h5 {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0em;
            text-align: left;
            
            span{
                font-weight:500;
                font-size:22px;
            }
        }

        &-left {
            display: flex;
            align-items: flex-end;

        }

        &-right {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 960px) {
    .footer {
        padding: 38px;
    
        &-container {
            flex-direction: column-reverse;
            margin-top: 25px;

            &-left {
    
                h6 {
                    font-size: 23px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: 0em;
                    color: #407BFF;
                    margin-bottom: 10px;
                }
    
                h1 {
                    font-size: 50px;
                    width: 296px;
    
                }
    
                &-desc {
                    font-size: 18px;
                }
            }

            &-right {
                flex: 50%;
                display: flex;
                justify-content: flex-start;
    
                img {
                    width: 149px;
                    height: 149px;
    
                }
            }
        }
        &-bottom {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            width: 100%;
    
            h5 {
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0em;
                text-align: left;
                
                span{
                    font-weight:500;
                    font-size:16px;
                }
            }
    
            &-left {
                display: flex;
                align-items: flex-end;
    
            }
    
            &-right {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }
        }
    }
}