.testimony-heading {
    display: flex;
    align-items: center;
    flex-direction: column;

    h6 {
        font-size: 20px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
        color: #555555;

    }

    h1 {
        max-width: 432px;
        font-size: 63px;
        font-weight: 700;
        line-height: 76px;
        letter-spacing: 0em;
        text-align: center;

    }
}

@media screen and (max-width: 960px) {
    .testimony-heading {
    
        h6 {
            font-size: 16px;
            line-height: 32px;
            margin-top: 50px;
            margin-bottom: 25px;
        }
    
        h1 {
            max-width: 299px;
            font-size: 50px;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: center;
    
        }
    }
}

