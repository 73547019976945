.navbar {
  background: $white;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid gray;
  box-shadow: 3px -8px 25px black;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    // max-width: 1500px;
    width: 100%;
  }

  &-logo {
    color: $black;
    justify-self: flex-start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.25rem;
    display: flex;
    align-items: flex-end;

    &-text {
      color: $black;
      font-size: 20px;
      line-height: 21px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 11px;
    }

    &-icon {
      margin-left: 0.5rem;
      position: relative;
      top:-10px;
      left:-5px;
      height: 3.5rem;
      width: 3.5rem;
    }
  }
}

.fa-map-marker-alt {
  margin-left: 0.5rem;
  height: 5.063rem;
  width: 5.625rem;
}

#nav-title {
  display: none;
}

#contactus-mobile{
  display: none;
}

.nav {
  &-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: center;
    margin-right: 2rem;
  }

  &-item {
    height: 80px;
  }

  &-links {
    color: $black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;

    &:hover {
      //   border-bottom: 4px solid $btn_white;
      color: #6966ff;
      font-weight: 600;
      transition: all 0.2s ease-out;
    }

    &-mobile {
      display: none;
    }
  }
}

.fa-map-marker-alt {
  color: $btn_white;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    height: 65px;
    border-bottom: 0px;
  }

  .NavbarItems {
    position: relative;
  }

  #nav-title {
    display: block;
    text-align: start;
    padding: 2rem;
    width: 100%;
    display: table;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
  }

  #contactus-mobile{
    display: block;
  }

  .nav {
    &-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      justify-content: flex-start;
      transition: all 0.5s ease;

      &.active {
        background: $white;
        left: 0;
        opacity: 1;
        // transition: all 0.5s ease;
        z-index: 1;
      }
    }

    &-links {
      text-align: start;
      padding: 2rem;
      width: 100%;
      display: table;
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0em;

      &:hover {
        background-color: $btn_white;
        color: $black;
        border-radius: 0;
        // border-bottom: 4px solid $btn_white;
        // transition: all 0.2s ease-out;
      }

      &-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: $btn_black;
        padding: 14px 20px;
        border: 1px solid $btn_white;
        transition: all 0.3s ease-out;
        display: none;

        &:hover {
          background: $btn_white;
          color: $btn_black;
          transition: 250ms;
        }
      }
    }
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    // transform: translate(25%, 50%);
    margin-left: 5px;

    &-text {
      color: $black;
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      position: relative;
      top:8px;
    }

    &-icon {
      position:relative;
      top:0px;
      margin-left: 1rem;
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: $btn_white;
    font-size: 2rem;
  }
}