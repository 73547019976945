.portfolio{
    text-align: center;
    &-imageConatiner {
        height: 300px;
        overflow: hidden;
     }
    &-card{
        border:1px solid lightgrey;
        display: inline-block;
        border-radius: 0px;
        max-width: 500px;
        margin: 20px;
        text-align: left;

       

        &-image{
            margin:5%;
            width: 90%;
            
        }

        &-title{
            margin-top: 20px;
            font-size:24px;
            margin-left: 5%;
            font-weight: 500;
        }
        &-description{
            font-size:16px;
            margin:5px 5% 20px 5%;
            color:grey;
            font-weight: 200;
        }
    }

    &-container{
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &-title{
        font-size: 45px;
        margin-top: 50px;
    }
}