.intro {
  &--container {
    display: flex;
    flex-direction: row;

    &--left {
      flex: 50%;
      &--bg {
        height: 731px;
        opacity: 3%;
      }
      &--content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: max-content;
        height: 980px;
        font-size: 70px;
        font-weight: 700;
        font-family: $fonts;
        line-height: 77px;
        top: 0px;
        left: 99px;
      }
      &--buttons{
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        gap: 15px;
      }
    }
    &--right {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &--image {
        height: 31.25rem;
        width: 31.25rem;
        margin-top: 20px;
      }
    }
  }
  &--products{
    display: flex;
    justify-content: center;
    padding-bottom:20px;
    border-bottom: 1px solid #DBD9D9;
    &--text{
        font-weight: 400;
        color: #747171;
        font-size: 27px;
        line-height: 87px;
        text-align: center;
    }
}
}

@media screen and (max-width: 960px) {
  .intro {
    &--container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      &--left {
        flex: 50%;
        &--bg {
          height: 731px;
          opacity: 3%;
          display: none;
        }
        &--content {
            position:unset;
            font-size: 45px;
            line-height: 45px;
            margin-top: 20px;
            height: 300px;
            // left: 47px;
        }
      }
      &--right {
        flex: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &--image {
          height: 160px;
          width: 160px;
        }
      }
    }
    &--products{
        display: flex;
        justify-content: center;
        &--text{
            font-weight: 400;
            color: #747171;
            font-size: 12px;
            line-height: 65px;
            text-align: center;
        }
    }
  }
}
