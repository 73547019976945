.customer-feedback-container{
    margin-top: 40px;
    margin-bottom: 60px;
    padding-left: 50px;
    padding-right: 50px;
}

.customer-card {
    
    border: 1px solid #5555;
    color: black;
    margin-left: 10px;
    padding: 30px;
    box-shadow: 0px 4px 7px -4px #00000040;


    p {
        font-size: 21px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;

    }

    &-profile {
        display: flex;
        margin-top: 20px;

        &-img {
            width: 68px;
            height: 72px;
            border-radius: 500px;
        }

    }

    &-content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h6 {
            font-size: 22px;
            font-weight: 700;
            text-align: left;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #5F5D5D;

        }
    }
}

@media screen and (max-width: 960px) {
    .customer-feedback-container{
        padding-left: 15px;
        padding-right: 15px;
    }

    .customer-card {
        max-width: 443px;
        border: 1px solid #5555;
        color: black;
        margin-left: 10px;
        padding: 30px;
        box-shadow: 0px 4px 7px -4px #00000040;
    
    
        p {
            font-size: 16px;
            line-height: 40px;
    
        }
    
        &-profile {
            display: flex;
            margin-top: 20px;
    
            &-img {
                width: 55px;
                height: 55px;
                border-radius: 500px;
            }
    
        }
    
        &-content {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h6 {
                font-size: 16px;
                font-weight: 700;
                text-align: left;
            }
    
            p {
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #5F5D5D;
    
            }
        }
    }
}