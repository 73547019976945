.contact {
 text-align: center;
 &-title{
   margin-top: 50px;
    font-size: 50px;
 }
 &-form{
   max-width: 650px;
   margin:50px auto;
   border:1px solid grey;
   // border-radius: 20px;
   padding: 40px;
   margin-bottom: 0px;
   background: white;
 }
 &-description{
   margin:20px auto;
   max-width: 550px;
   font-size: 20px;
   color:grey;
   font-weight: 400;
}

}

.contact-form-container{
   padding-bottom: 100px;
   background:linear-gradient(white 50%, black 50% 100%),
    url('https://images.unsplash.com/photo-1477414956199-7dafc86a4f1a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3870&q=80');
  
  /* the rest is just display setup. not relevant to your question */
  background-size: cover;

}


.contact-info{
   display: flex;
   justify-content:center;
   margin:10px;
}

.contact-info img{
   width: 35px;
   height:35px;
}

.contact-info span{
   color:black;
   text-decoration: none;
   font-size: 20px;
   position: relative;
   top:5px;
   margin-left: 10px;
}


.contact-info span a{
   color:black;
   text-decoration: none;
}

.contact .details-holder{
   background: white;
   min-height: 100px;
   margin: 0;
   display: block;
   margin-top: 30px;
}



.form-field input[type="text"]{
   height: 50px;
   width: 100%;
   margin-top: 5px;
   margin-bottom: 30px;
   border: 1px solid lightgrey;
   border-radius: 5px;
   font-size: 16px;
   text-indent: 10px;
}

.form-field textarea{
   height: 150px;
   width: 100%;
   margin-top: 5px;
   margin-bottom: 30px;
   border: 1px solid lightgrey;
   border-radius: 5px;
   text-indent: 10px;
   font-size: 16px;
   padding-top: 10px;
}

.contact .btn-holder{
   text-align: right;
}

.contact input[type="submit"]{
   height: 50px;
   width: 200px;
   background:black;
   color: white;
   font-size: 20px;
   border-radius: 40px;
}




.form-field label{
   display: block;
   text-align: left;
   font-weight:400;
   font-size: 18px;
}


@media screen and (max-width: 960px) {

   .contact-form{
      border:0px;
   }
 

}