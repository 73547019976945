.services {

    &-headings {
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        h5 {
            font-size: 20px;
            font-weight: 500;
            line-height: 87px;
            letter-spacing: 0em;
            text-align: left;
        }

        h1 {
            font-size: 63px;
            font-weight: 700;
            line-height: 76px;
            letter-spacing: 0em;
            text-align: center;
            max-width: 432px;
        }

        p {
            font-size: 23px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
            color: #555555;
            margin-top: 20px;
            max-width: 530px;
        }
    }

    &-container {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        margin-left: 96px;
        margin-right: 96px;
        gap: 20px;
    }
}

.technologies {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    h2 {
        width: 900px;
        text-align: center;
        border-bottom: 1px solid #B6B6B6;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    h2 span {
        background: #fff;
        padding: 0 10px;
        color: #B6B6B6;
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 0em;

    }

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-left: 120px;
        padding-right: 120px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &-bottom {
        font-size: 19px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #B6B6B6;
    }
}

.industries {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    h2 {
        width: 900px;
        text-align: center;
        border-bottom: 1px solid #B6B6B6;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    h2 span {
        background: #fff;
        padding: 0 10px;
        color: #B6B6B6;
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 0em;

    }

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-left: 120px;
        padding-right: 120px;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;

        h4 {
            font-size: 30px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
            color: #747474;
        }
    }

    &-bottom {
        font-size: 19px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #B6B6B6;
    }
}

.service-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    margin-top: 30px;
    margin-bottom: 30px;

    img {
        width: 128px;
        height: 128px;
    }

    h5 {
        font-size: 21px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 30px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #555555;
        margin-top: 30px;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 80px;
}

@media screen and (max-width: 960px) {

    .services {
        border-bottom: 1px solid #B6B6B6;


        &-headings {
            margin-top: 20px;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;

            h5 {
                font-size: 16px;
                line-height: 50px;
                text-align: center;
            }

            h1 {
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                max-width: 297px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: #555555;
                margin-top: 30px;
                max-width: 303px;
            }
        }
        &-container {
            display: flex;
            flex-direction: column;
            margin-left: 96px;
            margin-right: 96px;
            gap: 20px;
        }
    }

    .technologies {
        h2 {
            width: 100%;
        }
        h2 span {
            font-size: 16px;
        }

        &-container {
            flex-direction: column;
            padding-left: 40px;
            gap: 20px;
            padding-right: 40px;
            width: 100%;
            align-items: center;
        }

        &-bottom {
            font-size: 16px;
        }
    }

    .industries {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;

        h2 {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #B6B6B6;
            line-height: 0.1em;
            margin: 10px 0 20px;
        }

        h2 span {
            background: #fff;
            padding: 0 10px;
            color: #B6B6B6;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0em;

        }

        &-container {
            flex-direction: column;
            gap: 20px;
            padding-left: 40px;
            padding-right: 40px;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 30px;

            h4 {
                font-size: 20px;
            }
        }

        &-bottom {
            font-size: 16px;
        }
    }




    .button-container {
        display: none;
    }

}