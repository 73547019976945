@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


@import 'abstracts/variables';

@import 'base/reset';
@import 'base/base';

@import 'components/button';
@import 'components/customer-feedback-carousel';

@import 'layout/navbar';
@import 'layout/contact';
@import 'layout/about';
@import 'layout/intro-section';
@import 'layout/why-section';
@import 'layout/services-technology';
@import 'layout/testimony';
@import 'layout/footer';
@import 'layout/portfolio';


.slick-dots li button:before{
    font-size:14px;
    opacity:0.10;
}

.slick-dots{
    bottom:-38px;
}

#loading {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: white url('../assets/loader.gif') no-repeat center;
  }